import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, useMediaQuery } from '@mui/material';
import MainNavbar from './components/navbar';
import List from '@mui/material/List';
import SnipesList from './components/snipesList';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useState } from 'react';
import VictimsList from './components/victimsList';

function App() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#000',
      },
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#fff',
      },
    },
  });

  const theme = prefersDarkMode ? darkTheme : lightTheme;
  const [showVictims, setShowVictims] = useState(false);

  function toggleVictims() {
    setShowVictims(!showVictims);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <MainNavbar />
        <div style={{ maxWidth: '1350px', margin: '0 auto' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h5" fontWeight="bold" sx={{ py: 2, px: 2 }}>
              {showVictims ? 'Victims Leaderboard' : 'Snipes Leaderboard'}
            </Typography>
            <Button variant="outlined" sx={{ mr: 2 }} onClick={toggleVictims}>
              {showVictims ? <AddAPhotoIcon sx={{ mr: 1 }} /> : <PersonOffIcon sx={{ mr: 1 }} />}
              {showVictims ? 'Top Snipers' : 'Top Victims'}
            </Button>
          </div>

          <Box sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'center' : 'flex-start',
            justifyContent: 'space-between',
          }}>
            {/* Leaderboard List */}
            <Box sx={{ width: isMobile ? '100%' : '65%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1, backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#f5f5f5', borderRadius: '4px' }}>
                <Typography sx={{ width: '40px', ml: 1, mr: 3 }}>Rank</Typography>
                <Typography sx={{ flex: 1 }}>Name</Typography>
                <Typography sx={{ width: '100px', textAlign: 'center', mr: 2 }}>
                  {showVictims ? 'Kills' : 'Snipes'}
                </Typography>
              </Box>

              <List sx={{ pt: 2, px: 2 }}>
                {showVictims ? <VictimsList /> : <SnipesList />}
              </List>
            </Box>

            {/* How It Works Card */}
            <Box sx={{ width: isMobile ? '100%' : '35%', padding: isMobile ? '15px' : '0 15px', mb: isMobile ? 2 : 0 }}>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    How It Works
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    Snipe someone by taking a picture of them, tagging them on Slack, and posting it in the <strong>#snipes</strong> channel.
                    <br /><br />
                    Sniped someone, but it's not showing up here? Message <strong>Kevin Wang</strong> on Slack!
                    <br /><br />
                    Best of luck, and happy sniping! 📸
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Box>

          {/* Footer */}
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', py: 2 }}>
            <Typography variant="caption">
              Made with ❤️ by <span style={{ textDecoration: 'underline' }}><a href="http://www.kevindw.dev">Kevin Wang</a></span> for the V1 Community
            </Typography>
          </Box>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
